//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import GlobalMixin from '../../../../../shared/GlobalMixins.js';
import GamePlayMixin from '../../../../../shared/GamePlayMixin.js';
import InitializeGameMixin from "src/shared/InitializeGameMixin";

import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';

import levels from '../data/levels.json';
import dictionary from '../data/wordList.json';
import {SafeStringGenerator} from '../../../../../shared/SafeStringGenerator';

export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer')
  },
  data() {
    return {
      /* new wheel */
      // load from JSON
      blurWheel: false,
      bonusPrompt: false,
      buttonDisabled: true,
      container: null,
      correctThisRound: 0,
      currentStim: 1,
      dictionary: dictionary,
      firstSpin: true,
      lettersBeforeWord: 0,
      freeze: false,
      gamePlayCopy: gamePlayCopy,
      hintMode: true,
      incorrectAnswers: false,
      letterLength: 12,
      levels: levels,
      playDisabled: false,
      playStart: null,
      previousLetters: '',
      currentSpinCount: 0,
      spinThreshold: 2,
      rolling: false,
      spinning: false,
      totalAnswersPerTrial: 1,
      trialWasCorrect: false,
      wasJustIncorrect: false,
      isWheelRotating: false,
      rotationQueue: [],
      wrongAnswerThisTrial: false,
      wheelColor: '255, 255, 255',
      wheelDeg: 0,
      wordLength: 2,
      wordList: [],
      wordsPerTrial: 1,
      SafeStringGenerator: new SafeStringGenerator(),
      rotationDuration: 1.0,
      feedbackTimes: {
        feedbackInSpeed: 1000,
        feedbackLagSpeed: 1000,
        feedbackOutSpeed: 1000
      },
      gameValues: {
        correct_word_score: 100,
        word_miss_subtract: 50,
        early_word_subtract: 20,
        round_correct_score: 200,
        round_incorrect_score: 50
      },
      // letter data
      letterSequence: "",
      lettersBeginning: 0,
      lettersEnding: 0,
      targetWord: "",
      targetWordLetters: [],
      intervalTracker: [],
      gameData: {},
      session: {},
      letterArray: [],
      lastLetters: [],
      wheel: {
        selectedLetterIndex: null,
        background: 'white',
        foreground: 'white',
        timer: null,
        stopped: false
      },

      //Memorize
      inputword: '',
      correctWords: [],
      indexOfInput: 0,
      endRoundText: '',
      indexOfInputLabel: '1st',

      //UI relation

      actionTitle: "Click Action",
      arrowTitleClass: {
        'wordwheel__arrow-title': true
      },
      arrowClass: {
        'wordwheel__arrow-pointer': true
      },
      arrowLeft: {
        'wordwheel__arrow-left-point': true
      },

      inputWordClass: {
        'wordwheel__white_background': true
      },
      backgroundClass: {
        'wordwheel__incorrect_background': false,
        'wordwheel__correct_background': false
      },
      randomNumbers: {
        wheelDuration: null
      },
      intervals: {
        whiteBackground: null,
        wheelLetters: null,
        selectedIndex: null
      }

    };
  },
  methods: {
    /**
     * Main game play thread method, setups up letters for the wheel based on the current level
     * @returns {Promise<void>}
     */
    async initializeGamePlay() {
      this.setTrials(3); // set the number of trials for the game
      console.log('initializing gameplay');
      await this.getSettings(); // this will actually get the user, grid data based on level
      console.log('got settings');

      await this.buildLetterArray();
      console.log('built letter array');
      await this.animation.to(this.container, 0.9, {x: 0, ease: this.tweenExpo.tweenElastic});

      this.playStart = new Date().getTime(); // set the play start time
      if (!this.gamePlayComplete) {
        await this.sleep(1100);
        await this.startGamePlayMode();
      } else {
        this.setModalMessage({
          title: gamePlayCopy.gameComplete.playTitle,
          body: gamePlayCopy.gameComplete.playInstructions,
          actionButton: {action: this.leaveGame, text: gamePlayCopy.gameComplete.playButton}
        });
      }
    },
    /**
     * Gets the game settings for the current level
     * @returns {Promise<unknown>}
     */
    async getSettings() {
      let gameData = await this.startGamePlay({
          gameId: this.gameId, playId: this.play_id
        });
        this.setGameLevelData(gameData);
        this.setRoundId(gameData.round_id);
        this.setLevel(this.gameLevelData.start_level);
        this.wheelColor = this.gameLevelData.game_settings.level_color;
        this.lettersBeginning = this.gameLevelData.game_settings.letters_bwt_correct_min;
        this.lettersEnding = this.gameLevelData.game_settings.letters_bwt_correct_max;
        this.wordLength = this.gameLevelData.game_settings.word_length;
        this.spinThreshold = this.getLetterPadding() + 1;
        this.wordList = this.dictionary[this.wordLength];

        const newTargetWord = this.getRandomWord();
        this.targetWord = newTargetWord;
        this.targetWordLetters = newTargetWord.split('');

        this.currentSpinCount = 0;
        this.feedbackTimes.feedbackInSpeed = this.gameLevelData.game_settings.feedback_in_speed * 1000;
        this.feedbackTimes.feedbackLagSpeed = this.gameLevelData.game_settings.feedback_lag_speed * 1000;
        this.feedbackTimes.feedbackOutSpeed = this.gameLevelData.game_settings.feedback_out_speed * 1000;


        this.setRounds(4);
    },
    /**
     * Starts the game play mode, sets the letter sequence, and starts the timer
     * @returns {Promise<void>}
     */
    async startGamePlayMode() {
      this.playDisabled = false;
      this.resetModalMessage();

      this.trialStart = Date.now();
      this.session = {
        current_word: '',
        current_letter: 0
      };

      this.setMusic(this.assets.audio.gamePlay, true);
      this.rotateWheel();
      this.firstSpin = false;

      clearInterval(this.wheel.timer);
    },
    buildLetterArray(shift = true) {
      // Convert the letterSequence to an array for manipulation
      let letterArr = [...this.letterArray || []];
      if (letterArr.length < 12) {
        // If the length is less than 12, generate a new sequence of random letters to fill it out
        const letterCount = letterArr.length;
        for (let i = 0; i < 12 - letterCount; i++) {
          const consonantBank = ["M", "N", "S", "G", "D", "H", "F", "R", "T", "B", "W"]; // remove vowels
          const vowelBank = ["A", "O", "I", "U", "E", "Y"]; // vowels only

          let letterBank = this.getRandomIntegerInRange(0, 1) ? vowelBank : consonantBank;
          if (this.gameExited) break; // make sure if the user exits at this stage, we don't keep going
          // Generate a random letter from the letter bank and add it to the array
          let letter = letterBank[this.getRandomIntegerInRange(0, letterBank.length - 1)];

          letterArr.push(letter);
        }
      }

      // Update the component's state with the new arrays
      this.letterArray = letterArr;
    },

    fillLetterArrayToDesiredLength(letterArr) {
      const desiredLength = 12; // Assuming a fixed length for simplicity
      while (letterArr.length < desiredLength) {
        // randomly assign true or false to isVowel
        let isVowel = Math.random() < 0.5;
        let newLetter = this.getUniqueLetters(isVowel, 1).flat(); // Implement this method based on your requirements
        letterArr.push(newLetter);
      }
    },

    getNextDisplayLetter() {
      // Implement logic to determine the next letter to add for display
      // This could be based on ensuring no accidental word formations, etc.
      return this.getUniqueLetters(Math.random() < 0.5, 1).flat(); // Placeholder for actual logic
    },
    getUniqueLetters(isVowel, count) {
      const consonantBank = ["M", "N", "S", "G", "D", "H", "F", "R", "T", "B", "W"]; // remove vowels
      const vowelBank = ["A", "O", "I", "U", "E", "Y"]; // vowels only
      let letterBank = isVowel ? vowelBank : consonantBank;

      let resultArray = [];
      for (let i = 0; i < count; i++) {
        let letter = letterBank[this.getRandomIntegerInRange(0, letterBank.length - 1)];
        resultArray.push(letter);
      }
      return resultArray;
    },
    /**
     * Gets a random word from the word list for this level
     * @returns {string}
     */
    getRandomWord() {
      const randomWordIndex = Math.random() * this.wordList.length >> 0;
      return this.wordList[randomWordIndex];
    },
    getLetterPadding() {
      return this.getRandomIntegerInRange(this.lettersBeginning, this.lettersEnding);
    },
    /**
     * Checks if the letter sequence is in the word list
     * @returns {string}
     */
    isLetterSequenceInWordList(letterSequence) {
      if (letterSequence.length < this.wordLength) {
        return letterSequence.toUpperCase();
      }

      for (const word of this.wordList) {
        while (letterSequence.includes(word)) {
          let replacementSequence = '';
          for (let i = 0; i < this.wordLength; i++) {
            const charCode = this.getRandomIntegerInRange(65, 90);
            replacementSequence += String.fromCharCode(charCode);
          }
          letterSequence = letterSequence.replace(word, replacementSequence);
        }
      }

      return letterSequence.toUpperCase();
    },
    /**
     * Action button press event, checks if the user missed a word and marks as incorrect if so
     * @returns {Promise<void>}
     */
    async evaluateClick() {

      this.wheel.selectedLetterIndex = null;
      if (this.rolling || this.playDisabled || this.buttonDisabled) {
        this.setEffect(this.assets.audio.disabled);
        return;
      }

      // disable button so they can't click twice, especially if it's an incorrect answer
      this.buttonDisabled = true;
      this.playDisabled = true;
      this.stopMusic();

      if (!this.arePreviousLettersTargetWord()) {
        this.setEffect(this.assets.audio.incorrect);
        await this.showIncorrectScreen();
        this.wasJustIncorrect = true;
        this.playDisabled = false;
        this.decreaseScore(this.gameValues.word_miss_subtract);
        await this.sleep(2000);
        this.wrongAnswerThisTrial = true;
        await this.showNormalScreen();

        this.rotateWheel(true);
        return;
      } else {
        if (!this.wrongAnswerThisTrial) {
          this.trialWasCorrect = true;
        }
        this.setEffect(this.assets.audio.correct);
        await this.showCorrectScreen();

        if (!this.wrongAnswerThisTrial) {
          this.correctWords.push(this.targetWord);
          // this will change if stim per trial is more than 1
          this.correctThisRound++;
        }

        this.increaseScore(this.gameValues.correct_word_score);
        console.log('answer was correct, we should go to next phase');
      }

      await this.determineNextGamePhase();
    },
    insertTargetWordLetters() {
      if (this.targetWordLetters.length > 0) {
        // insert the first letter of this.targetWordLetters array into the position [2] of this.letterArray
        this.letterArray.splice(2, 0, this.targetWordLetters.shift());
      }
    },
    saveSpinLetter: function () {
      if (this.lastLetters.length < this.wordLength) {
        this.lastLetters.push(this.letterArray[2]);
      } else {
        this.lastLetters.shift();
        this.lastLetters.push(this.letterArray[2]);
      }
    },
    /**
     * Spins the wheel, adds letters to the sequence if necessary
     * @returns {boolean}
     */
    async rotateWheel(removeFirstLetter = false) {
      this.buttonDisabled = true;

      // we need to check if the game has been exited multiple times to make sure we don't keep going
      if (this.gameExited) return false;
      if (this.isWheelRotating) {
        this.rotationQueue.push(removeFirstLetter);
        return;
      }

      this.isWheelRotating = true;

      // clear all intervals to make sure we don't have overlap
      clearTimeout(this.sleepInterval);
      clearTimeout(this.intervals.wheelLetters);
      clearTimeout(this.intervals.selectedIndex);

      const wheelSpinDuration = this.startSpin();

      // Spin 1: wait until the wheel is in the middle of the spin
      //has timeout interval
      await this.sleep(wheelSpinDuration / 3);
      if (this.gameExited) return false;
      
      await this.sleep(500);
      // pop off the first letter if removeFirstLetter
      this.updateLetterSequence(removeFirstLetter);


      //has timeout interval
      await this.refreshLetter(wheelSpinDuration);

      this.currentSpinCount++;

      let letterSequence;
      // sanitize the letter sequence to make sure it doesn't contain any words in the dictionary
      letterSequence = this.SafeStringGenerator.checkAndReplace(this.letterArray.join(''));
      console.log('targetWord', this.targetWord,  'targetWordLetters', this.targetWordLetters);
      if (this.currentSpinCount >= this.spinThreshold && this.targetWordLetters.length > 0) {
        // Every time this method runs, we check if the targetWordLetters array has any letters left and if we've reached the spinThreshold, if so we insert the next letter from the targetWordLetters array into the letterArray
        const nextLetter = this.targetWordLetters.shift();
        letterSequence = this.SafeStringGenerator.insertLetter(letterSequence, nextLetter, 2);
        console.log('inserted letter', letterSequence, nextLetter);
      }

      console.log('letterSequence', letterSequence);

      this.letterArray = letterSequence.split('');

      // Spin 2: refresh the letter array
      this.saveSpinLetter();
      this.letterArray.shift();
      await this.sleep(500);
      // Spin 3: set selectedLetterIndex and action_btn_clicked
      //has timeout interval

      await this.finalizeSpin(wheelSpinDuration);
      if (this.gameExited) return false;
      this.wheel.selectedLetterIndex = 1; // it's always going to be the first letter in the letterArr
      await this.showNormalScreen();

      // Wait for x seconds before checking if the previous letters match the searched word
      this.buttonDisabled = false;
      await this.sleep(this.gameLevelData.game_settings.letter_display_interval * 1000);
      this.buttonDisabled = true;

      const isCorrect = await this.checkIfWordFound();

      this.isWheelRotating = false;

      if (this.rotationQueue.length > 0) {
        const nextRemoveFirstLetter = this.rotationQueue.shift();
        this.rotateWheel(nextRemoveFirstLetter);
      } else if (isCorrect) {
        this.rotateWheel();
      }
    },
    startSpin() {
      this.wheel.selectedLetterIndex = null;

      if (this.playDisabled) {
        return false;
      }

      this.rolling = true;
      this.blurWheel = true;
      const {wheelDeg} = this;
      const spins = 2;
      const sectorSize = 360 / 12; // Calculate the size of each sector

      const initialSectorIndex = 0; // The index of the starting sector (e.g. sector K is at index 0)

      const initialSectorRotation = initialSectorIndex * sectorSize; // Calculate the initial rotation of the starting sector
      const initialRotation = wheelDeg % sectorSize; // Store the initial rotation of the wheel

      const targetSectorIndex = 0; // The index of the target sector (e.g. target letter)
      const targetSectorRotation = targetSectorIndex * sectorSize; // Calculate the rotation of the target sector

      this.wheelDeg = wheelDeg - initialRotation + // Subtract the initial rotation from the final rotation
          spins * 360 +
          (targetSectorRotation - initialSectorRotation); // Calculate the exact rotation required to reach the target sector

      this.rotationDuration = this.getRandomFloatInRange(this.gameLevelData.game_settings.isi_min, this.gameLevelData.game_settings.isi_max);
    },
    updateLetterSequence(removeFirstLetter) {
      if (removeFirstLetter) {
        this.letterSequence = this.letterSequence.substring(1);
      }
    },
    refreshLetter(wheelSpinDuration) {
      return new Promise(resolve => {
        this.intervals.wheelLetters = setTimeout(() => {
          this.buildLetterArray(!this.firstSpin);
          resolve();
        }, (wheelSpinDuration / 3) + (this.gameLevelData.game_settings.letter_display_interval * 1000));
      });
    },
    async finalizeSpin(wheelSpinDuration) {
      await new Promise(resolve => {
        this.intervals.selectedIndex = setTimeout(() => {
          this.session.action_btn_clicked = false; // reset user action
          resolve();
        }, wheelSpinDuration / 3);
      });

      // Wait until the wheel has stopped spinning
      this.buttonDisabled = false;
      this.rolling = false;
      this.blurWheel = false;
      this.firstSpin = false;
      return true;
    },
    arePreviousLettersTargetWord() {
      const lastLettersString = this.lastLetters.join('');

      this.previousLetters = lastLettersString.substring(0, this.wordLength);
      this.previousLetters = this.previousLetters.toUpperCase();

      return this.targetWord === this.previousLetters;
    },
    /**
     * Checks if the previous letters match the target word
     * @returns {Promise<boolean>}
     */
    async checkIfWordFound(overrideClick = false) {
      // If action button has been clicked, exit early
      let wasActionButtonClicked = this.session.action_btn_clicked;
      if (wasActionButtonClicked) return;

      // Check if the target word is incorrect
      if (this.arePreviousLettersTargetWord()) {
        console.log('incorrect');

        this.trialWasCorrect = false;
        this.wheel.timer = false;
        this.incorrectAnswers = true;
        this.buttonDisabled = true;

        await this.showIncorrectScreen('Whoops!');
        this.decreaseScore(this.gameValues.word_miss_subtract);
        await this.determineNextGamePhase();
        return false;
      }
      else if (!wasActionButtonClicked && !this.gameExited) {
        return true;
      }
    },
    /**
     * Show regular gameplay screen
     * @returns {Promise<void>}
     */
    showNormalScreen() {
      this.arrowClass = {
        'wordwheel__arrow-pointer': true
      };

      this.arrowLeft = {
        'wordwheel__arrow-left-point': true
      };

      this.arrowTitleClass = {
        'wordwheel__arrow-title': true,
        'black-text': true
      };

      this.backgroundClass = {
        'wordwheel__incorrect_background': false,
        'wordwheel__correct_background': false
      };

      this.actionTitle = "Click Action";
    },
    /**
     * Show incorrect screen
     * @returns {Promise<void>}
     */
    showIncorrectScreen(title = 'Incorrect') {
      this.arrowClass = {
        'wordwheel__arrow-pointer--incorrect': true
      };

      this.arrowLeft = {
        'wordwheel__arrow-left-point--incorrect': true
      };

      this.arrowTitleClass = {
        'wordwheel__arrow-title': true,
        'white-text': true
      };

      this.actionTitle = title;

      this.backgroundClass = {
        'wordwheel__incorrect_background': true,
        'wordwheel__correct_background': false
      };
    },
    /**
     * Show correct screen
     * @returns {Promise<void>}
     */
    showCorrectScreen() {
      this.arrowClass = {
        'wordwheel__arrow-pointer--correct': true
      };

      this.arrowLeft = {
        'wordwheel__arrow-left-point--correct': true
      };

      this.arrowTitleClass = {
        'wordwheel__arrow-title': true,
        'white-text': true
      };

      this.actionTitle = "Correct";

      this.backgroundClass = {
        'wordwheel__incorrect_background': false,
        'wordwheel__correct_background': true
      };
    },
    /**
     * Determine next game phase
     * @returns {Promise<void>}
     */
    async determineNextGamePhase() {
      console.log('--- DETERMINE NEXT GAME PHASE ---');
      this.destroyIntervals();

      if (!this.trialWasCorrect) {
        this.trialWasCorrect = false;
        this.setTrialStatus('incorrect');
      } else {
        this.updateTrialsCorrect(this.trialsCorrect + 1);
        this.trialWasCorrect = true;
        this.setTrialStatus('correct');
      }

      this.wrongAnswerThisTrial = false;
      this.trialWasCorrect = false;

      await this.setGameLevelAndSaveTrialResults();
      await this.sleep(1500);
      await this.showNormalScreen();

      this.firstSpin = true;

      if (this.gamePlayComplete) {
        await this.handleGamePlayCompletion();
        return;
      }

      this.wheel.stopped = false;

      if (this.remainingTrials) {
        await this.goToNextTrial();
      } else {
        console.log('§§§ no more trials left, go to next round');
        await this.endRoundScore();
        await this.goToNextRound();
      }
    },

    async handleGamePlayCompletion() {
      await this.endRoundScore();
      this.increaseTickets(8);
      this.setTicketEffect();
      this.playDisabled = true;

      await this.endRoundScore();
      await this.updatePlayLevel({
        gameId: this.gameId,
        playId: this.play_id,
        levelId: this.level,
        stopTime: new Date().getTime()
      });

      this.movedUpALevel ? this.showMovedUpALevelModal() : this.showEndOfGameModal();
    },
    /**
     * Calculate end of round score + tickets
     * @returns {Promise<void>}
     */
    async endRoundScore() {
      if (this.allAnswersCorrect) {
        console.log('got all of the answers correct, go up a level');
        this.goUpLevel();
        this.increaseTickets(4);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('some answers are correct, stay at same level');
      }

      if (this.noAnswersCorrect) {
        console.log('all of the answers are wrong, go down a level');

        if (this.level > 1) this.goDownLevel();
      }

      await this.updateGameLevel();

      this.incorrectAnswers = false;
    },
    /**
     * Reset variables for next trial, and start the next trial
     * @returns {Promise<void>}
     */
    async goToNextTrial() {
      console.log('--- GO TO NEXT TRIAL ---');
      this.playDisabled = true;
      this.resetModalMessage();
      if (this.wordsPerTrial > 1 && this.currentStim < this.wordsPerTrial) {
        this.currentStim++;
      }

      await this.getSettings();

      // so we see stuff before it goes away
      // await this.sleep(3000);
      this.playDisabled = false;
      await this.startGamePlayMode();

      this.setTimer('false');

      this.startNewRound();
      if (this.currentTrial === 1) {
        this.correctThisRound = 0;
        this.resetTrialsCorrect();
        this.clearTrialStatus();
      } // reset this for new rounds

      this.playStart = new Date().getTime(); // start counting the next trial time
    },
    /**
     * Reset variables for next round, and start the next round
     * @returns {Promise<void>}
     */
    async goToNextRound() {
      console.log('--- GO TO NEXT ROUND ---');
      this.playDisabled = true;

      this.currentStim = 1;

      if (this.allAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.all;
      }

      if (this.someAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.some;
      }

      if (this.noAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      this.setEffect(this.assets.audio.roundEnd);

      // clear wheel rotate timer and all other timers so it doesn't keep spinning while round modal is up
      Object.keys(this.intervals).forEach(key => {
        clearTimeout(this.intervals[key]);
        clearInterval(this.intervals[key]);
        this.intervals[key] = null;
      });

      // Set modal message for end of round
      this.setModalMessage({
        title: this.formatText(
            gamePlayCopy.rounds.end,
            {round: this.currentRound},
            false
        ),
        body: this.formatText(this.endRoundText, {correct: this.trialsCorrect}),
        actionButton: {
          action: this.correctWords.length > 0 ? this.showBonus : this.goToNextTrial,
          text: this.correctWords.length > 0 ? gamePlayCopy.rounds.playNextBonus : gamePlayCopy.rounds.playNext
        }
      });
    },
    /**
     * Confirm the word entered by the user
     * @returns {Promise<void>}
     */
    async confirmAction() {
      console.log('----confirm----');
      if (this.inputword === '' || this.inputword.length < 2) {
        return;
      }

      const isCorrect = this.correctWords[this.indexOfInput] === this.inputword.toUpperCase();
      this.inputWordClass = {
        'wordwheel__green_background': isCorrect,
        'wordwheel__red_background': !isCorrect
      };

      if (isCorrect) {
        this.increaseScore(this.gameValues.round_correct_score);
      }

      this.intervals.whiteBackground = setTimeout(() => {
        this.inputWordClass = {
          'wordwheel__white_background': true
        };
        this.resetInputWordPrompt();
      }, 1000);

      if (this.correctWords.length !== this.indexOfInput + 1) return;

      if (this.movedUpALevel) {
        this.showMovedUpALevelModal();
        return;
      }

      if (this.gamePlayComplete) {
        await this.handleGamePlayCompletion();
        return;
      }

      await this.goToNextTrial();

    },
    /**
     * Reset the input word prompt
     * @returns {Promise<void>}
     */
    resetInputWordPrompt() {
      this.indexOfInput += 1;
      this.inputword = '';
      if (this.indexOfInput === 1) {
        this.indexOfInputLabel = '2nd';
      } else if (this.indexOfInput === 2) {
        this.indexOfInputLabel = '3rd';
      }

      if (this.indexOfInput >= this.correctWords.length) {
        this.bonusPrompt = false;
        this.playDisabled = false;

        this.correctWords = [];
        this.indexOfInput = 0;
        this.indexOfInputLabel = '1st';
      }

      this.$refs.bonusInput.focus();
    },
    /**
     * Show the bonus screen
     * @returns {Promise<void>}
     */
    async showBonus() {
      console.log('---£ show bonus £---');
      await this.showNormalScreen();
      if (this.correctWords.length === 0) {
        this.bonusPrompt = false;
        this.playDisabled = false;

        this.correctWords = [];
        this.indexOfInput = 0;
        this.indexOfInputLabel = '1st';

      } else {
        console.log('this.correctWords', this.correctWords);
        this.bonusPrompt = true;
        this.playDisabled = true;
        this.resetModalMessage();
      }

    },
    /**
     * Compute the classes for the letters in the wheel
     * @param index
     * @returns {string}
     */
    letterClasses(index) {
      let classes = this.wheel.selectedLetterIndex === index ? 'current-letter' : '';
      classes += this.blurWheel ? ' blur' : '';
      classes += ' index-' + index;
      return classes;
    },
    destroyIntervals() {
      // Clear the timers
      clearTimeout(this.intervals.whiteBackground);
      clearTimeout(this.intervals.wheelLetters);
      clearTimeout(this.intervals.selectedIndex);

      // Remove the reactive properties
      Vue.delete(this.intervals, 'whiteBackground');
      Vue.delete(this.intervals, 'wheelLetters');
      Vue.delete(this.intervals, 'selectedIndex');

      this.intervals = {};
    }
  },
  computed: {
    actionSubtitle() {
      if (this.actionTitle === 'Incorrect') {
        return "You're a little early.";
      } else if (this.actionTitle === 'Whoops!') {
        return "You missed a word.";
      } else {
        return "To Mark A <span class='wordwheel__arrow-desc-span'>" + this.wordLength + "</span> letter word";
      }

    },
    allAnswersCorrect() {
      return this.trialsCorrect === this.trials;
    },
    someAnswersCorrect() {
      return this.trialsCorrect > 0 && this.trialsCorrect < this.trials;
    },
    noAnswersCorrect() {
      return this.trialsCorrect === 0;
    }
  },
  created() {
    // initialize the random number generators
    Object.keys(this.randomNumbers).forEach(key => {
      this.randomNumbers[key] = this.createUniqueRandomGenerator();
    });
  },
  async mounted() {
    this.destroyIntervals();

    await this.initializeGamePlay(); // start round 1, trial 1
  },
  beforeDestroy() {
    this.destroyIntervals();
  }
};
