import dictionary from '../components/GamePlay/Games/WordWheel/data/wordList.json';
export class SafeStringGenerator {
    constructor() {
        this.twoLetterDict = dictionary['2'];
        this.threeLetterDict = dictionary['3'];
        this.fourLetterDict = dictionary['4'];
    }

    generateRandomLetter(exclude = []) {
        const consonantBank = ["M", "N", "S", "G", "D", "H", "F", "R", "T", "B", "W"]; // remove vowels
        const vowelBank = ["A", "O", "I", "U", "E", "Y"]; // vowels only
        const alphabet = consonantBank.join('') + vowelBank.join('');
        let validLetters = alphabet.split('').filter(letter => !exclude.includes(letter));
        return validLetters[Math.floor(Math.random() * validLetters.length)];
    }

    replaceWordWithRandomLetters(string, startIndex, wordLength, preserveIndex = null) {
        let newString = string.split('');
        for (let i = startIndex; i < startIndex + wordLength; i++) {
            if (i !== preserveIndex) {
                // Generate a new letter, ensuring it doesn't recreate a word with the preserved letter
                // This may need additional logic to ensure the new letter doesn't form new unwanted words
                let exclude = [newString[i]]; // Optionally add more letters to exclude based on dictionary checks
                newString[i] = this.generateRandomLetter(exclude);
            }
        }
        return newString.join('');
    }

    checkAndReplace(string, preserveIndex = null) {
        let newString = string;
        const dictionaries = [this.twoLetterDict, this.threeLetterDict, this.fourLetterDict];

        dictionaries.forEach((dict, index) => {
            const wordLength = index + 2; // Based on dictionary (2, 3, or 4 letters)
            for (let i = 0; i <= newString.length - wordLength; i++) {
                let word = newString.substring(i, i + wordLength).toUpperCase();
                if (dict.includes(word)) {
                    // Directly replacing words that do not involve the preserveIndex
                    if (preserveIndex === null || preserveIndex < i || preserveIndex >= i + wordLength) {
                        newString = this.replaceWordWithRandomLetters(newString, i, wordLength);
                    } else if (i <= preserveIndex && preserveIndex < i + wordLength) {
                        newString = this.replaceWordWithRandomLetters(newString, i, wordLength, preserveIndex);
                    }
                    i--; // Adjust index to recheck due to potential cascading changes
                }
            }
        });

        return newString;
    }

    insertLetter(string, letter, position) {
        let newString = string.substring(0, position) + letter + string.substring(position);
        newString = this.checkAndReplace(newString, position); // Now passing the preserveIndex
        return newString;
    }

    generateSafeString() {
        let randomString = '';
        for (let i = 0; i < 12; i++) {
            randomString += this.generateRandomLetter();
        }
        return this.checkAndReplace(randomString);
    }
}