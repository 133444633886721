import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/WordWheel/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
    correct: 'correct.mp3',
    incorrect: 'incorrect.mp3',
    disabled: 'disabled.mp3',
    roundEnd: 'round-end.mp3'
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    backgroundCorrect: 'background-correct.webp',
    backgroundIncorrect: 'background-incorrect.webp',
    pointer: 'pointer.webp',
    modal: 'background-modal.webp'
  }
};

export default generateAssets(assetsDir, gameAssets);