export default {
  gamePlay: {
    playTitle: "Instructions!",
    playInstructions: "<p>The wheel keeps on spinning, spinning.</p>Watch for the letters to spell a word!",
    playButton: 'Start WordWheel!'
  },
  rounds: {
    end: "End of Round {{round}}",
    playNext: "Play next round!",
    playNextBonus: "Start bonus round!",
    all: "Excellent! You got {{correct}} words correct this round! Now, type them in order from first to last.",
    some: "Great job! You got {{correct}} words correct in this round. Now, type them in order from first to last.",
    none: "Good try. Let's keep practicing!"
  },
  gameComplete: {
    playTitle: "Game Completed!",
    playInstructions: "Great work, keep practicing!",
    playButton: 'Return to games'
  },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    instructions: [
        {
            'title': 'Instructions',
            'body': 'The wheel keeps on spinning, spinning...<br>\n' +
                'Watch for the letter to spell a word!',
            'buttonText': 'Got it!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Instructions',
            'body': 'As you level up, you\'ll look for longer words.',
            'buttonText': 'Got it!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Instructions',
            'body': 'Click the RED BUTTON when you spot a word with the right number of letters.<br>\n' +
                'Remember them for BIG POINTS in the Bonus round!',
            'buttonText': 'Got it!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'yes',
            'buttonText2': 'no',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
}
